import {
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../product-card';
import { setPage, setProductCard } from '../../redux/homeSlice';
import { useQuery } from '../../hooks/useQuery';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useParams } from 'react-router-dom';
import {
  fetchAllProducts,
  setIsListingLoading,
  setProducts,
} from '../../redux/productSlice';
import { hasObjectChanged } from '../../utils/functions';
import { useDecryptParam } from '../../hooks/useDecryptParam';
import isEmpty from 'is-empty';

let tt;
let count = 0;

const ProductListing = ({
  fromHome,
  onCartOpen,
  fromListing,
  preventApiCall,
}) => {
  const { page } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { filters, products, isListingLoading, totalCount } = useSelector(
    (state) => state.product
  );
  const [isSmallerThan560] = useMediaQuery('(max-width: 560px)');
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan960] = useMediaQuery('(max-width: 960px)');
  const [isSmallerThan1185] = useMediaQuery('(max-width: 1185px)');
  const [isSmallerThan1430] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1530] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1800] = useMediaQuery('(max-width: 1800px)');
  const [isSmallerThan1920] = useMediaQuery('(max-width: 1920px)');

  const dispatch = useDispatch();
  const query = useQuery();
  const name = useParams();
  const categoryId = useDecryptParam(query.get('categoryId'));
  const min_discount = useDecryptParam(query.get('min_discount'));
  const max_discount = useDecryptParam(query.get('max_discount'));
  const min_price = useDecryptParam(query.get('min_price'));
  const max_price = useDecryptParam(query.get('max_price'));
  const is_free_shipping = useDecryptParam(query.get('is_free_shipping'));
  const is_sold = useDecryptParam(query.get('is_sold'));
  const search_key = query.get('search_key');
  const key = query.get('key');
  const banner = query.get('banner');
  const location = useLocation();

  const [scrollCount, setScrollCount] = useState(0);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [filterObj, setFilterObj] = useState({});

  const [isChanged, setIsChanged] = useState(false);

  const fetchData = useCallback(() => {
    const params = {
      page_no: page,
      categoryId,
      min_discount,
      // user_id: userData?.id || guestInfo?.id,
      max_discount,
      is_free_shipping: is_free_shipping ? 1 : 0,
      is_sold,
      rating: filters?.rating ? 5 : undefined,
      best_match: filters?.best_match,
      order: filters?.order || undefined,
      isPrice: filters?.isPrice > 0 ? filters?.isPrice : 0,
      sale: filters?.sale > 0 ? 1 : 0,
      min_price: filters?.min || 0,
      max_price: filters?.max || undefined,
    };
    if (categoryId) {
      params.categoryId = categoryId;
    }
    if (min_price) {
      params.min_price = filters?.min || min_price;
    }
    if (max_price) {
      params.max_price = filters?.max || max_price;
    }
    if (search_key) {
      params.search_key = search_key;
    }
    if (key) {
      params.key = key;
    }
    if (fromHome) {
      params.is_home = 1;
    }
    if (isEmpty(filters?.min) || isEmpty(filters?.max)) {
      delete params.min_price;
      delete params.max_price;
    }
    const user_id = userData?.id || guestInfo?.id;
    if (user_id) {
      dispatch(fetchAllProducts({ ...params, user_id }));
    }
    // if (userData?.id) {
    //   dispatch(getProductsHandler({ ...params, userId: userData.id }));
    // } else if (guestInfo?.id) {
    //   dispatch(getProductsHandler({ ...params, guestId: guestInfo.id }));
    // }
    setIsFetching(false);
    count++;
  }, [
    categoryId,
    userData?.id,
    guestInfo?.id,
    filters,
    search_key,
    key,
    fromHome,
    page,
    min_price,
    max_price,
    is_free_shipping,
    is_sold,
  ]);

  const fetchMoreData = () => {
    if (isFetching) return;
    const nextPage = page + 1;
    dispatch(setPage(nextPage));
    setIsFetching(true);

    const params = {
      page_no: nextPage,
      categoryId,
      // user_id: userData?.id || guestInfo?.id,
      min_discount,
      max_discount,
      is_free_shipping: is_free_shipping ? 1 : 0,
      is_sold,
      rating: filters?.rating > 0 ? filters?.rating : 0,
      best_match: filters?.best_match,
      order: filters?.order || undefined,
      isPrice: filters?.isPrice || undefined,
      sale: filters?.sale ? 1 : undefined,
      min_price: filters?.min || 0,
      max_price: filters?.max || undefined,
      user_id: userData?.id || guestInfo?.id,
    };
    if (categoryId) {
      params.categoryId = categoryId || '';
    }
    if (min_price) {
      params.min_price = min_price;
    }
    if (max_price) {
      params.max_price = max_price;
    }
    if (search_key) {
      params.search_key = search_key;
    }
    if (key) {
      params.key = key;
    }
    if (fromHome) {
      params.is_home = 1;
    }
    if (userData?.id || guestInfo?.id) {
      dispatch(fetchAllProducts(params, 'more')).then((res) => {
        if (res.status === 200) {
          setScrollCount((prev) => prev + 1);
          if (scrollCount + 1 == 4) {
            setShowMoreButton(true);
          }
        }
        setIsFetching(false);
      });
    }
  };

  useEffect(() => {
    setFilterObj({ ...filters });
  }, []);

  console.log(filters, 'filters');

  useEffect(() => {
    setIsChanged(hasObjectChanged(filterObj, filters));
  }, [filterObj, filters]);

  useEffect(() => {
    dispatch(setProducts([]));
    if (tt) {
      clearTimeout(tt);
    }
    if (!preventApiCall) {
      tt = setTimeout(() => {
        if (isEmpty(products)) {
          fetchData();
        }
      }, 500);
    }

    return () => {
      clearTimeout(tt);
    };
  }, [filters, preventApiCall]);

  console.log(products, 'products');

  useEffect(() => {
    if (isChanged) {
      dispatch(setProducts([]));
    }
  }, [dispatch, isChanged]);

  const handleShowMore = () => {
    setScrollCount(0);
    setShowMoreButton(false);
    fetchMoreData();
  };

  const slidesPerView = location.pathname.includes('/product/listing')
    ? isSmallerThan560
      ? 2
      : 4
    : isSmallerThan560
    ? 2
    : isSmallerThan767
    ? 2
    : isSmallerThan960
    ? 2
    : isSmallerThan1185
    ? 3
    : isSmallerThan1430
    ? 4
    : isSmallerThan1530
    ? 5
    : isSmallerThan1800
    ? 6
    : isSmallerThan1920
    ? 6
    : 6;

  return (
    <>
      {!isListingLoading && totalCount === 0 && products.length === 0 && (
        <Text fontSize={'18px'} p='5' textAlign={'center'}>
          No Product found!
        </Text>
      )}

      {isListingLoading && products.length === 0 ? (
        <Flex
          justify={'center'}
          align={'center'}
          padding={{ base: '10px 10px 50px 10px', md: '25px' }}
        >
          <Spinner />
        </Flex>
      ) : (
        <InfiniteScroll
          dataLength={products.length}
          next={scrollCount < 4 ? fetchMoreData : () => {}} // Stop scroll after 4
          hasMore={scrollCount < 4 && products.length < totalCount} // Ensure "View More" appears after 4 scrolls
          loader={
            totalCount !== 0 &&
            scrollCount < 4 &&
            isFetching && (
              <Flex
                justify={'center'}
                align={'center'}
                padding={{ base: '10px 10px 50px 10px', md: '25px' }}
              >
                <Spinner />
              </Flex>
            )
          }
        >
          <SimpleGrid
            columns={slidesPerView}
            gridTemplateColumns={`repeat(${slidesPerView}, minmax(${
              isSmallerThan767 ? '145px' : '160px'
            }, 1fr))`}
            spacing={'13px'}
            mb={{ base: '50px', md: '20px' }}
          >
            {products?.map((item, index) => (
              <ProductCard
                onCartOpen={() =>
                  fromListing ? onCartOpen(item) : onCartOpen(item)
                }
                item={item}
                onMouseEnter={() => {
                  const map = products.map((productItem, itemIndex) => ({
                    ...productItem,
                    showIcons: index === itemIndex,
                  }));
                  dispatch(setProductCard(map));
                }}
                onMouseLeave={() => {
                  const map = products.map((productItem) => ({
                    ...productItem,
                    showIcons: false,
                  }));
                  dispatch(setProductCard(map));
                }}
                fromListing
              />
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      )}
      {showMoreButton && (
        <Flex justify={'center'}>
          <Button
            cursor='pointer'
            fontSize={14}
            fontWeight={600}
            textAlign='center'
            bg='black'
            color='white'
            onClick={handleShowMore}
            colorScheme='teal'
            // m='20px'
            m={{ base: '10px 10px 50px 10px', md: '20px' }}
            mx='auto'
            display='block'
            border='none'
            mb={{ base: '50px', md: '20px' }}
          >
            View More
          </Button>
        </Flex>
      )}
    </>
  );
};

export default ProductListing;
