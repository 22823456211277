import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Cart from '../../pages/product/cart';

const CartMobile = ({ isOpen, onClose }) => {
  useEffect(() => {
    document.body.classList.add('cart-mobile-open');

    return () => {
      document.body.classList.remove('cart-mobile-open');
    };
  }, []);

  return (
    <Box className={'cart-mobile'}>
      <Drawer isOpen={isOpen} placement='bottom' onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody padding={0}>
            <Cart />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default CartMobile;
