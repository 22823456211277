import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Flex,
    Input,
    SimpleGrid,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProductCard } from '../../redux/homeSlice';
import { getRecentlyViewed, setProductSkuId } from '../../redux/productSlice';
import ProductCard from '../../components/product-card';
import ProductDetail from '../product-detail';
import Modal from '../../components/modal';

const RecentlyViewed = () => {
    const {
        isOpen: isOpenCart,
        onOpen: onOpenCart,
        onClose: onCloseCart,
    } = useDisclosure();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [productData, setProductData] = useState(null);
    const recentlyViewedList = useSelector(
        (state) => state.product.recentlyViewedList
    );

    useEffect(() => {
        dispatch(getRecentlyViewed());
    }, [dispatch]);

    const cartHandler = (data) => {
        dispatch(setProductSkuId(data?.sku_id));
        setProductData(data);
        onOpenCart();
    };

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredRecentlyViewedList = recentlyViewedList?.filter((item) =>
        item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {recentlyViewedList?.length === 0 && (
                <Flex
                    flexDir={'column'}
                    gap={'10px'}
                    justify={'center'}
                    align={'center'}
                    p='50px'
                    minH='50vh'
                    textAlign='center'
                >
                    <Text fontSize={'24px'}>No products viewed recently!</Text>
                    <Text>Add items to it now.</Text>
                    <Button variant='primary' onClick={() => navigate('/')}>
                        Shop Now
                    </Button>
                </Flex>
            )}

{recentlyViewedList?.length !== 0 && (
            <Flex
                p='1.25rem'
                flexDir={'row'}
                className='box'
                justify={'space-between'}
                align={'center'}
            >
                <Text fontSize={'.9375rem'}>
                    Total <Badge mx='5px'>{filteredRecentlyViewedList.length}</Badge> products are
                    wishlisted
                </Text>
                <Flex gap={'10px'}>
                    <Input
                        placeholder='Search'
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        focusBorderColor={'primary.100'}
                    />
                    <Button variant={'primary'}>Search</Button>
                </Flex>
            </Flex>)}

            {filteredRecentlyViewedList?.length > 0 ? (
                <>
                    <SimpleGrid spacing='20px' 
                        mb={{ base: '50px', md: '20px' }} 
                        columns={[2, 3, 4, 5]}>
                        {filteredRecentlyViewedList.map((item, index) => (
                            <ProductCard
                                key={item?.sku_id}
                                item={item}
                                onCartOpen={() => cartHandler(item)}
                                onMouseEnter={() => {
                                    const map = filteredRecentlyViewedList.map((item, itemIndex) => {
                                        if (index === itemIndex) {
                                            return { ...item, showIcons: true };
                                        }
                                        return { ...item, showIcons: false };
                                    });
                                    dispatch(setProductCard(map));
                                }}
                                onMouseLeave={() => {
                                    const map = filteredRecentlyViewedList.map((item, itemIndex) => {
                                        if (index === itemIndex) {
                                            return { ...item, showIcons: false };
                                        }
                                        return { ...item, showIcons: false };
                                    });
                                    dispatch(setProductCard(map));
                                }}
                            />
                        ))}
                    </SimpleGrid>
                </>
            ) : (
                filteredRecentlyViewedList?.length === 0 && searchTerm && (
                    <Flex
                        flexDir={'column'}
                        gap={'10px'}
                        justify={'center'}
                        align={'center'}
                        p='50px'
                        minH='50vh'
                    >
                        <Text fontSize={'24px'}>No Matched results found!</Text>
                    </Flex>
                )
            )}

            {/* Modal for Product Details */}
            <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
                <ProductDetail fromHome={'fromHome'} sku_id={productData?.sku_id} />
            </Modal>
        </>
    );
};

export default RecentlyViewed;
