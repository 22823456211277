import { Avatar, Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { RiCameraLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { profileUpdateHandler } from '../../redux/userSlice';
import FormInput from '../../components/form-input';

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { userData } = useSelector((state) => state.user);
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = async (event) => {
    const _file = event.target.files[0];

    if (!_file.type.includes('image')) {
      toast({
        title: 'Please upload an image file',
        status: 'error',
      });
      return;
    }
    setFile(_file);
  };
  return (
    <Box p={'32px'} borderRadius={'4px'} border={'1px solid #f3f3f3'}>
      <Box height={'100px'} w={'100px'} pos={'relative'} mb='1.63rem'>
        <Flex
          height={'30px'}
          width={'30px'}
          borderRadius={'50%'}
          bg='#ffefee'
          position={'absolute'}
          right={0}
          top={0}
          justify={'center'}
          align={'center'}
          zIndex={1}
          cursor={'pointer'}
          onClick={handleClick}
        >
          <RiCameraLine />
        </Flex>
        {file || userData?.image ? (
          <Avatar
            height={'100px'}
            w={'100px'}
            src={userData?.image ? userData?.image : URL.createObjectURL(file)}
          />
        ) : (
          <Avatar height={'100px'} w={'100px'} />
        )}
      </Box>
      <input
        type='file'
        accept='image/png, image/gif, image/jpeg'
        onChange={handleFileChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }} // Make the file input element invisible
      />
      <Text fontWeight={600} fontSize={'20px'}>
        {' '}
        Personal Information
      </Text>
      <Box w={'100%'}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: userData?.name.split(' ')[0] || '',
            last_name:
              userData?.last_name || userData?.name.split(' ')[1] || '',
            phone: userData?.phone || '',
            email: userData?.email || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            phone:
              Yup.string()
              .required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
          })}
          onSubmit={(values) => {
            const payload = {
              user_id: userData.id,
              name: values.name,
              last_name: values.last_name,
              phone: values.phone,
              email: values.email,
              image: file,
            };
            dispatch(profileUpdateHandler(payload)).then((res) => {
              if (res.status === 200) {
                toast({
                  title: 'Profile Updated Successfully',
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                toast({
                  title: res?.response?.data?.message ?? 'Something went wrong',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              }
            });
          }}
          validateOnMount
        >
          {({ isValid }) => (
            <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
              <Flex flexDir={'column'} gap={'20px'}>
                <Flex gap={'20px'} flexDir={{ base:'column', md:'row'}}>
                  <FormInput
                    name='name'
                    type='text'
                    label='First Name'
                    placeholder='Full Name'
                    flexWidth={'100%'}
                  />
                  <FormInput
                    name='last_name'
                    type='text'
                    label='Last Name'
                    placeholder='Last Name'
                    flexWidth={'100%'}
                  />
                </Flex>
                <Flex gap={'20px'} flexDir={{ base:'column', md:'row'}}>
                  <FormInput
                    name='email'
                    type='text'
                    label='Email Address'
                    placeholder='Email Address'
                    flexWidth={'100%'}
                  />

                  <FormInput
                    name='phone'
                    type='number'
                    label='Phone'
                    placeholder='Phone'
                    flexWidth={'100%'}
                  />
                </Flex>
              </Flex>

              <Flex 
              mt={{base:'2px', md:'20px'}} 
              mb={{base:'20px', md:'0px'}}
              justify={{base:'center', md:'flex-end'}}

              >
                {' '}
                <Button
                  variant={'primary'}
                  isDisabled={!isValid}
                  size={'sm'}
                  type='submit'
                  w={'200px'}
                  // isLoading={isLoading}
                  loadingText='Please wait...'
                >
                  Save Changes
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default PersonalInformation;
