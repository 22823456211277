import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Box,
  VStack,
  Spinner,
  Text,
  Flex,
} from "@chakra-ui/react";
import NotificationCard from "./notification-card";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { fetchNotificationsHandler, readNotificationsHandler } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";

const notifications = [
  {
    id: 1,
    name: "Emperio",
    message: "Project assigned by the manager all files and folders were included",
    time: "12 mins ago",
    date: "24, Oct 2024",
    active: true,
  },
  {
    id: 2,
    name: "Dwayne Bero",
    message: "Admin and other team accepted your work request",
    time: "17 mins ago",
    date: "30, Sep 2024",
    active: true,
  },
  {
    id: 3,
    name: "Alister Chuk",
    message: "Temporary data will be deleted once dedicated time complated",
    time: "4 hrs ago",
    date: "11, Sep 2024",
    active: false,
  },
  {
    id: 4,
    name: "Melissa Blue",
    message: "Approved date for sanction of loan is verified",
    time: "5 hrs ago",
    date: "18, Sep 2024",
    active: true,
  },
];

const NotificationList = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(true);
  const { notificationsList } = useSelector((state) => state?.user);

  useEffect(() => {
    dispatch(readNotificationsHandler(userData?.id));
  }, []);

  console.log(notificationsList, "notification list")
  return (

    <Box minH='50vh'>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
        p="25px 20px 0px 20px"
      >
        <Text fontSize="20px" fontWeight="bold">
          Notifications
        </Text>
        <Flex alignItems="center" gap="10px">
          <Text color="#E6533C" fontSize="14px">
            Pages
          </Text>
          <RiArrowRightDoubleFill color="#E6533C" />
          <Text fontWeight="bold" fontSize="14px">
            Notifications
          </Text>
        </Flex>
      </Flex>

      <Box p={4} m="10px" borderRadius="10px" minH='50vh'>
        {(!notificationsList || notificationsList.length === 0) && (
          <Flex
            flexDir={'column'}
            gap={'10px'}
            justify={'center'}
            align={'center'}
            verticalAlign='center'
            textAlign='center'
            p='50px'
          >
            <Text fontSize={'24px'}>No new Notifications!</Text>
            <Text>Currently You don't have any notifications.</Text>
          </Flex>
        )}


        <Box >
          <Box p={4} m="10px" borderRadius="10px">
            <VStack spacing={4}>
              {notificationsList?.map((notification, index) => (
                <React.Fragment key={notification?.id}>
                  <NotificationCard notification={notification} />
                </React.Fragment>
              ))}
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationList;
