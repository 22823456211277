import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './header.module.scss';
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  Select,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '../icons/search';
import CartIcon from '../icons/cart';
import { CiBellOn, CiGlobe } from 'react-icons/ci';
import HeartIcon from '../icons/heart';
import MenuIcon from '../icons/menu';
import UserIcon from '../icons/user';
import Modal from '../modal';
import LoginPage from '../../pages/login';
import { useDispatch, useSelector } from 'react-redux';
import Signup from '../../pages/signup';
import {
  fetchAllCategories,
  getCheckCartFunction,
  saveSearchHandler,
  setCountry,
  setCurrency,
  setIsCategoryOpen,
  setIsLogin,
  setOnLoginOpen,
  setSelectedCountry,
  setSubCategoryOpen,
} from '../../redux/homeSlice';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import Categories from './categories';
import { RiArrowLeftSLine, RiCloseFill } from 'react-icons/ri';
import Dropdown from '../dropdown';
import { currencyList } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { fetchSuggestions, setSuggestions } from '../../redux/productSlice';
import Logo from './logo';

import { v4 as uuidv4 } from 'uuid';
import { setGuestInfo } from '../../redux/guestSlice';
import { fetchCartList } from '../../redux/cartSlice';
import NotificationIcon from '../notificationIcon';
import { usehashParam } from '../../hooks/useHashParam';
import Cart from '../../pages/product/cart';

let tt;

let count = 0;

const Header = () => {
  const {
    isOpen: isDropdownOpen,
    onOpen: onDropdownOpen,
    onClose: onDropdownClose,
  } = useDisclosure();
  const {
    isOpen: isUserDropdownOpen,
    onOpen: onUserDropdownOpen,
    onClose: onUserDropdownClose,
  } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isWishlistPage = location.pathname === '/wishlist';
  const isCartPage = location.pathname === '/product/cart';
  const { t, i18n } = useTranslation();

  const {
    isLogin,
    onLoginOpen,
    currency,
    country,
    countries,
    isCategoryOpen,
    allCategories,
  } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { cartDetails } = useSelector((state) => state.cart);
  const { wishList } = useSelector((state) => state.wishlist);
  const { suggestions } = useSelector((state) => state.product);

  const [lang, setLang] = useState('en');
  const [searchQuery, setSearchQuery] = useState('');
  const ref = useRef();
  const [menuState, setMenuState] = useState(null);

  const [isGlobIconHovered, setIsGlobIconHovered] = useState(false);
  const [isNotificationHovered, setIsNotificationHovered] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const isAuthenticated = localStorage.getItem('token') ? true : false;
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  // check cart in every 3 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getCheckCartFunction());
    }, 180000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useOutsideClick({
    ref: ref,
    handler: () => {
      dispatch(setSuggestions([]));
      setSearchQuery('');
      setMenuState(null);
    },
  });

  const handleMenuClick = () => {
    handleDrawerToggle();
  };

  const handleDrawerToggle = () => {
    dispatch(setIsCategoryOpen(!isCategoryOpen));
  };

  useEffectOnce(() => {
    if (allCategories.length === 0 && count === 0) {
      dispatch(fetchAllCategories());
      count++;
    }
  });

  useEffect(() => {
    if (isCategoryOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isCategoryOpen]);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,fr,sw', // include this for selected languages
        // layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element'
    );
  };
  const addScript = useCallback(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    const country = localStorage.getItem('country');
    if (isDropdownOpen || country) {
      addScript();
    }
  }, [addScript, isDropdownOpen]);

  useEffect(() => {
    const _country = localStorage.getItem('country') || country;
    const _currency = localStorage.getItem('currency') || currency;

    dispatch(setCountry(_country));
    if (_country) {
      dispatch(setCurrency(_currency));
      setCurrencies(
        currencyList.filter(
          (c) => c.code === 'USD' || c.code.includes(_country)
          // c.code === 'EUR' || removed Euro
        )
      );

      dispatch(setSelectedCountry(countries.find((c) => c.iso === _country)));

      // document.cookie = `googtrans=/en/${country}`;
    }
  }, [countries, country, currency, dispatch]);

  const gotToPageList = (value) => {
    if (value) {
      window.location.href = `/product/listing?search_key=${value}&is_search=true`;
      // dispatch(saveSearchHandler(value));
      console.log('search key saved');
      dispatch(setSuggestions([]));
    } else {
      dispatch(setSuggestions([]));
    }
  };

  const isAccountPage = location.pathname.includes('/account');
  const isSettingsPage = location.pathname.includes('/settings');

  useEffect(() => {
    if (tt) {
      clearTimeout(tt);
    }
    tt = setTimeout(() => {
      dispatch(fetchCartList(userData?.id || guestInfo.id));
    }, 500);
  }, []);

  const handleModalClose = () => {
    dispatch(setIsLogin(true));
    dispatch(setOnLoginOpen(false));
    console.log('Modal is closing, Login state:', isLogin, onLoginOpen);
  };

  const scrollTopFunction = () => window.scrollTo(0, 0);

  return (
    <Box
      className={styles.header}
      onClick={() => dispatch(setSubCategoryOpen(false), scrollTopFunction())}
      style={{ display: isAccountPage || isSettingsPage ? 'none' : 'block' }}
    >
      <Flex className={styles.bottom_nav} gap={'110px'} align={'center'}>
        <Flex className={styles.burger_menu_icon} w={'20%'} align={'center'}>
          <Flex gap={'16px'} align={'center'}>
            {(isSmallerThan767 && location.pathname.includes('listing')) ||
            (isSmallerThan767 &&
              location.pathname.includes('product/details')) ||
            (isSmallerThan767 && location.pathname.includes('order-detail')) ||
            (isSmallerThan767 && location.pathname.includes('my-orders')) ? (
              <Flex
                align={'center'}
                justify={'center'}
                width={'48px'}
                height={'48px'}
                bg='#FFEFEE'
                borderRadius={'8px'}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <RiArrowLeftSLine color='#F41106' size={'40px'} />
              </Flex>
            ) : (
              <Box
                className='noSelect'
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuClick();
                }}
                cursor='pointer'
              >
                {!isCategoryOpen ? (
                  <MenuIcon />
                ) : (
                  <Flex
                    align={'center'}
                    justify={'center'}
                    width={'48px'}
                    height={'48px'}
                    bg='#FFEFEE'
                    borderRadius={'8px'}
                  >
                    <RiCloseFill color={'#F41106'} size={'30px'} />
                  </Flex>
                )}
              </Box>
            )}

            <NavLink to={'/'}>
              <Logo styles={styles} />
            </NavLink>
          </Flex>
        </Flex>
        <Flex
          className={styles.right__flex}
          flex={1}
          gap={'30px'}
          justify={'space-between'}
          onClick={() => {
            dispatch(setIsCategoryOpen(false));
            onDropdownClose();
          }}
        >
          <Box
            ref={ref}
            w={'60%'}
            className={styles.input__group}
            pos={'relative'}
          >
            <InputGroup>
              <InputLeftElement
                cursor={'pointer'}
                onClick={(e) => {
                  e.stopPropagation();
                  gotToPageList(searchQuery);
                }}
                height={'48px'}
                className='noSelect'
              >
                <SearchIcon />
              </InputLeftElement>
              <Input
                borderRadius={'10px'}
                bg={'#FFF'}
                placeholder='Search for products, best deals and more...'
                _hover={{ bg: '#F1F2F4', boxShadow: 'none' }}
                w={'100%'}
                border={'1px solid #000'}
                height={'48px'}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchQuery(value);

                  if (value.length >= 3) {
                    dispatch(fetchSuggestions(value));
                  } else {
                    dispatch(setSuggestions([]));
                  }
                }}
                value={searchQuery}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    gotToPageList(e.target.value);
                  }
                }}
              />
            </InputGroup>
            {suggestions.length > 0 && (
              <Box className={styles.suggestion_box}>
                {suggestions.map((suggestion) => (
                  <Box
                    key={suggestion.id}
                    className={styles.suggestion}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (suggestion.type === 1) {
                        setSearchQuery('');
                        window.location.href = `/product/listing?category_id=${suggestion.id}&is_search=true`;
                      } else {
                        window.location.href = `/product/listing?search_key=${searchQuery}&is_search=true`;
                      }
                      // dispatch(saveSearchHandler(searchQuery));
                      dispatch(setSuggestions([]));
                    }}
                  >
                    <Flex gap={'10px'} align={'center'}>
                      <Image
                        src={
                          suggestion.image ||
                          require('../../assets/img/product/no-image.png')
                        }
                        w={'40px'}
                        h={'40px'}
                        objectFit={'cover'}
                        borderRadius={'10px'}
                        bg={'#F3F6F8'}
                        margin={'5px'}
                      />
                      <Box>
                        <Box
                          fontSize={'1rem'}
                          fontWeight={'400'}
                          marginBottom={'2px'}
                          textOverflow={'ellipsis'}
                          overflow={'hidden'}
                          whiteSpace={'nowrap'}
                          maxW={'320px'}
                          dangerouslySetInnerHTML={{
                            __html: suggestion.name,
                          }}
                        ></Box>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Flex
            gap={'16px'}
            align={'center'}
            className={styles.icons_menu_flex}
          >
            <Flex
              borderRight={'1px solid #D9D9D9'}
              height={'24px'}
              align={'center'}
              pos={'relative'}
              pr='16px'
              cursor={'pointer'}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onDropdownClose();
                  if (isUserDropdownOpen) {
                    onUserDropdownClose();
                  } else {
                    onUserDropdownOpen();
                  }
                }}
              >
                <UserIcon
                  size={24}
                  color={isUserDropdownOpen ? 'red' : '#808080'}
                />
              </span>

              <Dropdown
                isOpen={isUserDropdownOpen}
                onClose={onUserDropdownClose}
                w='260px'
              >
                <Flex
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  minW='255px'
                  maxW='260px !important'
                  flexDir='column'
                  gap='20px !important'
                >
                  <Box>
                    {userData?.name ? (
                      <Box>
                        <b>{userData?.name}</b>
                      </Box>
                    ) : (
                      <Box onClick={() => dispatch(setOnLoginOpen(true))}>
                        <b>Sign in / Register</b>
                      </Box>
                    )}

                    <hr />
                    <hr />
                    {isAuthenticated && (
                      <Box mt='10px'>
                        <NavLink to='/my-orders'>My orders</NavLink>
                      </Box>
                    )}
                    <Box mt='10px'>
                      <NavLink to='/wishlist'>My wish list</NavLink>
                    </Box>
                    {userData?.name && (
                      <Box mt='10px'>
                        <NavLink to='/profile'>My Profile</NavLink>
                      </Box>
                    )}

                    {isAuthenticated && (
                      <Box mt='10px'>
                        <NavLink to='/notifications'>My inbox</NavLink>
                      </Box>
                    )}

                    <Box mt='10px'>
                      <NavLink to='/qna'>Support</NavLink>
                    </Box>

                    <Box mt='10px'>
                      <NavLink to='/recently-viewed'>Recently Viewed</NavLink>
                    </Box>
                    {userData?.name ? (
                      <Box
                        mt={5}
                        onClick={() => {
                          localStorage.clear();
                          localStorage.setItem('isAuthenticated', 'true');
                          let guestId = uuidv4();
                          localStorage.setItem(
                            'guestInfo',
                            JSON.stringify({ id: guestId })
                          );
                          dispatch(setGuestInfo(guestId));
                          dispatch(setIsLogin(false));
                          window.location.href = '/';
                        }}
                      >
                        Logout
                      </Box>
                    ) : null}
                  </Box>
                </Flex>
              </Dropdown>
            </Flex>

            <Flex
              borderRight={'1px solid #D9D9D9'}
              height={'24px'}
              align={'center'}
              pos={'relative'}
              pr='16px'
              cursor={'pointer'}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onUserDropdownClose();
                  if (isDropdownOpen) {
                    onDropdownClose();
                  } else {
                    onDropdownOpen();
                  }
                }}
                onMouseEnter={() => setIsGlobIconHovered(true)}
                onMouseLeave={() => setIsGlobIconHovered(false)}
              >
                <CiGlobe
                  size={24}
                  color={
                    isDropdownOpen || isGlobIconHovered ? 'red' : '#808080'
                  }
                />
              </span>

              <Dropdown isOpen={isDropdownOpen} onClose={onDropdownClose}>
                <Flex
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  minW={'255px'}
                  flexDir={'column'}
                  gap={'10px'}
                >
                  <FormControl>
                    <FormLabel
                      fontSize={'15px'}
                      fontWeight={600}
                      lineHeight={'19px'}
                    >
                      Language
                    </FormLabel>
                    <Box id='google_translate_element'></Box>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontSize={'15px'}
                      fontWeight={600}
                      lineHeight={'19px'}
                    >
                      Country
                    </FormLabel>
                    <Select
                      placeholder='Select option'
                      onChange={(e) => {
                        dispatch(setCountry(e.target.value));
                        localStorage.setItem('country', e.target.value);

                        dispatch(
                          setCurrency(
                            currencyList.find((c) =>
                              c.code.includes(e.target.value)
                            ).code
                          )
                        );
                      }}
                      value={'TZ'} // Set default value for Tanzania
                      isDisabled={true} // Disable the dropdown
                    >
                      {countries.map((country) => (
                        <option key={country.iso} value={country.iso}>
                          {country.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel
                      fontSize={'15px'}
                      fontWeight={600}
                      lineHeight={'19px'}
                    >
                      Currency
                    </FormLabel>
                    <Select
                      placeholder='Select option'
                      value={currency}
                      onChange={(e) => {
                        localStorage.setItem('currency', e.target.value);
                        dispatch(setCurrency(e.target.value));
                      }}
                    >
                      {currencies.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    mt={'10px'}
                    variant={'primary'}
                    onClick={() => {
                      localStorage.setItem('country', country);
                      localStorage.setItem('currency', currency);
                      window.location.reload();
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Dropdown>
            </Flex>
            
            <Flex
              borderRight={'1px solid #D9D9D9'}
              pr='16px'
              height={'24px'}
              align={'center'}
              gap={'5px'}
            >
              <NavLink to={'/wishlist'}>
                <Flex align={'center'} gap={'5px'}>
                  <HeartIcon
                    color={
                      isWishlistPage || wishList.length > 0 ? 'red' : '#808080'
                    }
                  />{' '}
                  {wishList.length > 0 && <Badge>{wishList.length}</Badge>}
                </Flex>
              </NavLink>
            </Flex>

            <Flex
              height={'24px'}
              align={'center'}
              borderRight={'1px solid #D9D9D9'}
              pr='16px'
            >
              <Menu closeOnSelect={false}>
                <MenuButton
                onClick={(e) => {
                  e.stopPropagation(); 
                  if (isCartPage) {
                    e.preventDefault(); 
                  }
                }}
                >
                  <Flex align={'center'} gap={'5px'}>
                    <CartIcon
                      color={
                        isCartPage || cartDetails?.data?.length > 0
                          ? 'red'
                          : '#808080'
                      }
                    />

                    <Flex align={'center'} gap={'10px'}>
                      Cart{' '}
                      {cartDetails?.data?.length > 0 && (
                        <Badge>{cartDetails?.data?.length}</Badge>
                      )}
                    </Flex>
                  </Flex>
                </MenuButton>
                <MenuList width={'470px'} padding={'0 !important'}>
                  <Cart fromHeader />
                </MenuList>
              </Menu>
              {/* <NavLink to={'/product/cart'}>
                <Flex align={'center'} gap={'5px'}>
                  <CartIcon
                    color={
                      isCartPage || cartDetails?.data?.length > 0
                        ? 'red'
                        : '#808080'
                    }
                  />

                  <Flex align={'center'} gap={'10px'}>
                    Cart{' '}
                    {cartDetails?.data?.length > 0 && (
                      <Badge>{cartDetails?.data?.length}</Badge>
                    )}
                  </Flex>
                </Flex>
              </NavLink> */}
            </Flex>

            <Flex
              height={'24px'}
              align={'center'}
              pos={'relative'}
              pr='16px'
              cursor={'pointer'}
              mt='10px'
            >
              <NotificationIcon count={5} />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className={styles.notification_icon}
          height={'24px'}
          align={'center'}
          pos={'relative'}
          pr='16px'
          cursor={'pointer'}
        >
          <NotificationIcon count={5} />
        </Flex>
      </Flex>
      {isCategoryOpen && <Categories />}

      <Modal
        width={isSmallerThan767 ? 'calc(100% - 25px)' : '600px'}
        isOpen={onLoginOpen}
        onClose={() => handleModalClose()}
        isMobileMargin={isSmallerThan767 ? '0 20px' : 'auto'}
      >
        {isLogin ? (
          <LoginPage onClose={() => dispatch(setOnLoginOpen(false))} />
        ) : (
          <Signup onClose={() => dispatch(setOnLoginOpen(false))} />
        )}
      </Modal>
    </Box>
  );
};

export default React.memo(Header);
