import { useContext, useEffect } from 'react';
import { ScrollContext } from '../context/scroll-context';
import { useLocation } from 'react-router-dom';

export const useScrollRestoration = () => {
  const scrollPositions = useContext(ScrollContext);
  const location = useLocation();

  useEffect(() => {
    // Restore scroll position or default to top
    const position = scrollPositions.current[location.pathname] || 0;
    window.scrollTo(0, position);

    return () => {
      // Save current scroll position before unmounting
      scrollPositions.current[location.pathname] = window.scrollY;
    };
  }, [location.pathname]);
};
