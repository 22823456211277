import React, { useState, lazy, Suspense, useEffect } from 'react';
import './index.scss';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Select,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import Filters from '../../components/filters';
import SortBy from '../../components/sortby';
import Modal from '../../components/modal';
import ProductDetail from '../product-detail';
import { FaFilter } from 'react-icons/fa';
import { useQuery } from '../../hooks/useQuery';
import { useSearchParams } from 'react-router-dom';
import { saveSearchHandler } from '../../redux/homeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../redux/productSlice';
import { usehashParam } from '../../hooks/useHashParam';

const ProductListing = lazy(() => import('../../components/product-listing'));
let tt;
const Listing = () => {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [preventApiCall, setPreventApiCall] = useState(false);
  const categoryId = searchParams.get('categoryId');
  const titleFromQuery = searchParams.get('title'); // Query title from URL
  const subcategoryName = searchParams.get('name')
    ? decodeURIComponent(searchParams.get('name').replace(/-/g, ' ')).replace(
        '&',
        'And'
      ) // Decode subcategory name and replace & with And
    : null;

  // Determine what title to show: prioritizing title from query over subcategory name
  let pageTitle = titleFromQuery || subcategoryName;

  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan1260] = useMediaQuery('(max-width: 1260px)');
  const [skuId, setSkuId] = useState('');
  const btnRef = React.useRef();
  const query = useQuery();

  const { mobileFilters } = useSelector((state) => state.product);

  const isSearch = searchParams.get('is_search') === 'true';
  const searchKey = searchParams.get('search_key') || '';

  const queryParams = {};
  for (const entry of searchParams.entries()) {
    // make object key value pair

    queryParams[entry[0]] = entry[1];
  }

  useEffect(() => {
    if (tt) {
      clearTimeout(tt);
    }
    tt = setTimeout(() => {
      if (isSearch && searchKey) {
        dispatch(saveSearchHandler(searchKey));
      }
    }, 500);
  }, [isSearch, searchKey]);

  const cartOpen = (id) => {
    setSkuId(id);
    onOpenCart();
  };

  const handleOpenFilter = () => {
    if (isSmallerThan767) {
      setPreventApiCall(true);
    }
    onOpenFilter();
  };

  const handleCloseFilter = () => {
    setPreventApiCall(false);
    onCloseFilter();
  };

  return (
    <>
      {pageTitle && (
        <>
          <Flex
            padding={'20px'}
            justify={'center'}
            fontSize='17px'
            fontWeight='bold'
          >
            <Text>{pageTitle.replace(/\sand\s/gi, ' & ')}</Text>{' '}
            {/* Replace "and" with "&" */}
          </Flex>
          {!isSmallerThan767 && <hr />}
        </>
      )}
      <div className='product-listing'>
        {!isSmallerThan767 && (
          <Box width={'15%'} p={'25px 0px 25px 0'} minW={'245px'}>
            <Filters />
          </Box>
        )}

        <Box p={isSmallerThan767 ? '0' : '25px 0 0 25px'} flex={1}>
          <SortBy onFilterOpen={handleOpenFilter} />
          <Suspense fallback={<Spinner />}>
            <ProductListing
              fromListing
              onCartOpen={cartOpen}
              preventApiCall={preventApiCall}
            />
          </Suspense>
        </Box>

        <Drawer
          isOpen={isOpenFilter}
          placement='right'
          onClose={() => {
            // if (isSmallerThan767) {
            //   dispatch(
            //     setFilters({
            //       isFreeShipping: mobileFilters.isFreeShipping,
            //       rating: mobileFilters.rating,
            //     })
            //   );

            //   setSearchParams({
            //     ...queryParams,
            //     is_free_search: true,
            //     is_rating_search: true,
            //     is_free_shipping: usehashParam(
            //       mobileFilters.isFreeShipping ? 1 : 0
            //     ),
            //     rating: usehashParam(mobileFilters.rating),
            //   });
            //   if (!mobileFilters.isFreeShipping) {
            //     delete queryParams.is_free_search;

            //   }
            //   if (mobileFilters.rating !== 5) {
            //     delete queryParams.is_rating_search;

            //   }
            //   setSearchParams({
            //     ...queryParams,
            //   });
            // }
            onCloseFilter();
          }}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton onClick={onCloseFilter} />
            <DrawerHeader>Filter</DrawerHeader>

            <DrawerBody>
              <Filters preventClose={() => {}} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
          <ProductDetail fromHome sku_id={skuId} />
        </Modal>
      </div>
    </>
  );
};

export default Listing;
