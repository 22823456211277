import { createContext, useRef } from 'react';

// Context for managing scroll positions
export const ScrollContext = createContext();

export const ScrollRestorationProvider = ({ children }) => {
  const scrollPositions = useRef({});

  return (
    <ScrollContext.Provider value={scrollPositions}>
      {children}
    </ScrollContext.Provider>
  );
};
