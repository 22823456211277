import { Checkbox, useMediaQuery } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { usehashParam } from '../../hooks/useHashParam';
import { useDispatch } from 'react-redux';
import { setMobileFilters } from '../../redux/productSlice';

const CheckboxComponent = React.memo(
  ({
    filters,
    setSearchParams,
    queryParams,
    isFreeShipping,
    setIsFreeShipping,
  }) => {
    const dispatch = useDispatch();
    const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

    const handleChange = useCallback(
      (e) => {
        e.stopPropagation();
        setIsFreeShipping(e.target.checked);
        if (isSmallerThan767) {
          dispatch(setMobileFilters({ isFreeShipping: e.target.checked }));

          return;
        }
        setSearchParams({
          ...queryParams,
          is_free_shipping: e.target.checked
            ? usehashParam(1)
            : usehashParam(0),
          is_free_search: e.target.checked,
        });
      },
      [setSearchParams, queryParams]
    );

    return (
      <Checkbox isChecked={isFreeShipping} onChange={handleChange}>
        Free Shipping
      </Checkbox>
    );
  }
);

export default CheckboxComponent;
