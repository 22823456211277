import CryptoJS from 'crypto-js';

const secretKey = 'mySecretKey';

export function useDecryptParam(encryptedParam) {
  if (!encryptedParam || typeof encryptedParam !== 'string') {
    return '';
  }

  // Restore original Base64 encoding
  const base64 = encryptedParam.replace(/-/g, '+').replace(/_/g, '/');
  const bytes = CryptoJS.AES.decrypt(base64, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  if (!originalText) {
    throw new Error('Decryption failed');
  }
  return originalText;
}
