import React, { useEffect, useState } from 'react';
import { Button, HStack, Input } from '@chakra-ui/react';

const NumberGroupInput = ({
  isName,
  value: initialValue = 1,
  onChange,
  maxQuantity = Infinity,
  fromHeader = false,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onDecrementClick = () => {
    if (value <= 1) return;
    const newValue = value - 1;
    setValue(newValue);
    onChange(newValue);
  };

  const onIncrementClick = () => {
    if (value >= maxQuantity) return; // Prevent increment if value reaches maxQuantity
    const newValue = value + 1;
    setValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseInt(inputValue, 10);

    if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= maxQuantity) {
      setValue(parsedValue);
      onChange(parsedValue);
    } else if (inputValue === '') {
      setValue('');
    }
  };

  return (
    <HStack gap={0}>
      {isName && (
        <p className='name_sclr'>
          {' '}
          {isName} : {'  '}{' '}
        </p>
      )}
      <Button
        padding='0'
        maxWidth={fromHeader ? '10px' : '15px'}
        height={fromHeader ? '20px' : '25px'}
        width={fromHeader ? '10px' : '15px'}
        fontSize={fromHeader ? '10px' : '12px'}
        color='black'
        background='#ddd'
        onClick={onDecrementClick}
        isDisabled={value <= 1}
        _disabled={{ background: '#EDEDED', cursor: 'not-allowed' }}
      >
        -
      </Button>
      <Input
        onChange={handleInputChange}
        value={value}
        textAlign={'center'}
        padding='0'
        maxWidth={fromHeader ? '25px' : '35px'}
        minWidth='20px'
        width={fromHeader ? '25px' : '35px'}
        height={fromHeader ? '25px' : '30px'}
        fontSize={fromHeader ? '10px' : '14px'}
        border='transparent'
        type='number'
        min='1'
      />
      <Button
        padding='0'
        maxWidth={fromHeader ? '10px' : '15px'}
        height={fromHeader ? '20px' : '25px'}
        width={fromHeader ? '10px' : '15px'}
        fontSize={fromHeader ? '10px' : '12px'}
        color='black'
        background='#ddd'
        onClick={onIncrementClick}
        isDisabled={value >= maxQuantity}
        _disabled={{ background: '#EDEDED', cursor: 'not-allowed' }}
      >
        +
      </Button>
    </HStack>
  );
};

export default NumberGroupInput;
