import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Box, Divider, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usehashParam } from '../../hooks/useHashParam';

const HotDealBanner = ({ items, slidesPerView, spaceBetween }) => {
  const navigate = useNavigate();
  const {
    homeData: { hot_deals },
  } = useSelector((state) => state.home);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    if (hot_deals?.length <= 1) {
      const nextButton = document.querySelector('.swiper-button-next');
      const prevButton = document.querySelector('.swiper-button-prev');
      if (nextButton && prevButton) {
        nextButton.style.display = 'none';
        prevButton.style.display = 'none';
      }
    }
  }, [hot_deals]);

  return (
    <Box className='deal-banner' mb='40px'>
      <Text
        fontSize={isSmallerThan767 ? '18px' : '24px'}
        fontWeight={700}
        lineHeight={'30px'}
        color={'#666'}
        mb='30px'
      >
        Top <span style={{ color: '#F41106' }}>Hot Deals</span>
      </Text>
      <Divider mb='35px' mt='-10px' />
      <Swiper
        loop={true}
        className='mySwiper seven_tinPd'
        pagination={{
          clickable: true,
        }}
        // allowSlidePrev={true}
        // allowSlideNext={true}
        modules={[Pagination, Navigation]}
        // navigation
        slidesPerView={isSmallerThan767 ? 1 : slidesPerView}
        spaceBetween={spaceBetween}
        onSlideChange={() => {}}
        // onSwiper={(swiper) => console.log(swiper)}
        onSwiper={(swiper) => {
          if (hot_deals.length <= 1) {
            swiper.navigation.disable();
          } else {
            swiper.navigation.enable();
          }
        }}
      >
        {hot_deals.map((item, index) => (
          <SwiperSlide key={index}>
            <Image
              borderRadius={'16px'}
              h={'207'}
              w={'100%'}
              // objectFit={'cover'}
              src={
                isSmallerThan767
                  ? item.mobile_image != null && item.mobile_image != ''
                    ? item.mobile_image
                    : item.image
                  : item.image
              }
              cursor={'pointer'}
              onClick={() => {
                navigate(
                  `product/listing?page_no=1&min_discount=${usehashParam(
                    item.min_discount
                  )}&max_discount=${usehashParam(
                    item.max_discount
                  )}&is_free_shipping=${usehashParam(
                    item.is_free_shipping
                  )}&is_sold=${usehashParam(
                    item.is_sold
                  )}&rating=${usehashParam(
                    item.rating
                  )}&search_key=${usehashParam(
                    item.search_key
                  )}&min_price=${usehashParam(
                    item?.min_price
                  )}&max_price=${usehashParam(
                    item?.max_price
                  )}&name=${encodeURIComponent(
                    item.title.replace(/ /g, '-')
                  )}&categoryId=${
                    usehashParam(item?.sub_to_subcategory_id) || ''
                  }`
                );
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default HotDealBanner;
