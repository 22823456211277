import CryptoJS from 'crypto-js';
import isEmpty from 'is-empty';

const secretKey = 'mySecretKey';

// Hash the query parameter
export function usehashParam(param) {
  if (isEmpty(param)) {
    return '';
  }
  const encrypted = CryptoJS.AES.encrypt(
    param.toString(),
    secretKey
  ).toString();
  // Replace +, /, and = for URL safety
  return encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
