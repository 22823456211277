import { Flex, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { PiChatCircleDotsLight } from 'react-icons/pi';
import { RiEdit2Line } from 'react-icons/ri';
import AddNewSuggestion from '../add-new-suggestion';
import { useSelector } from 'react-redux';
import ChatBot from '../chatBot';
import { useLocation } from 'react-router-dom';

const ActionButtons = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [isChatBotVisible, setChatBotVisible] = useState(false);
  const {
    isOpen: isOpenSuggestions,
    onOpen: onOpenSuggestions,
    onClose: onCloseSuggestions,
  } = useDisclosure();
  const { productDetails } = useSelector((state) => state.product);
  const isCartPage = location.pathname === '/product/cart';
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1200) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour 
             in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  const handlePencilClick = () => {
      onOpenSuggestions();
  };

  const handleContactClick = () => {
    setChatBotVisible(true); 
  };
  
  const handleChatBotClose = () => {
    setChatBotVisible(false); 
  };

  return (
    <>
      <Flex
        flexDir={'column'}
        gap={'10px'}
        pos={'fixed'}
        right={{base:'5px', md:'40px'}}
        bottom={{base:'100px', md:'40px'}}
        zIndex={1}
        className='action-buttons'
        mb={isCartPage ? { base: '50px', md: '0' } : '0'}
      >
        <Flex
          border={'2px solid #ddd'}
          height={'40px'}
          width={'40px'}
          borderRadius={'50%'}
          fontSize={'20px'}
          align={'center'}
          justify={'center'}
          cursor={'pointer'}
          onClick={handleContactClick}
          bg={{ base: '#EDEDED', md: 'transparent' }}
        >
          <PiChatCircleDotsLight />
        </Flex>
        <Flex
          border={'2px solid #ddd'}
          height={'40px'}
          width={'40px'}
          borderRadius={'50%'}
          fontSize={'20px'}
          align={'center'}
          justify={'center'}
          cursor={'pointer'}
          onClick={handlePencilClick}
          bg={{ base: '#EDEDED', md: 'transparent' }}
        >
          <RiEdit2Line />
        </Flex>
        {visible && (
          <Flex
            border={'2px solid #ddd'}
            height={'40px'}
            width={'40px'}
            borderRadius={'50%'}
            fontSize={'20px'}
            align={'center'}
            justify={'center'}
            cursor={'pointer'}
            onClick={scrollToTop}
            bg={{ base: '#EDEDED', md: 'transparent' }}
          >
            <BsChevronUp />
          </Flex>
        )}
      </Flex>

      {isChatBotVisible && <ChatBot onClose={handleChatBotClose}/>}

      <AddNewSuggestion
        isOpen={isOpenSuggestions}
        onClose={onCloseSuggestions}
      />
    </>
  );
};

export default ActionButtons;
