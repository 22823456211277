import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Box, Text, Divider, Image } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setGuestInfo } from '../../redux/guestSlice';
import AccountHeader from '../../components/header/account-header';
import LogoutIcon from '../../components/icons/exit-icon';
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import airtelMoney from "../../assets/img/icons/airtel-money.png";
import eziPay from "../../assets/img/icons/ezipay-logo.svg";
import mPesa from "../../assets/img/icons/mpesa.jpg";
import payPal from "../../assets/img/icons/payPal.svg";
import yasPay from "../../assets/img/icons/yasLogo.svg";

const customerServices = [
  { name: 'About Us', path: '/about-us' },
  { name: 'Terms & Conditions', path: '/terms-and-conditions' },
  { name: 'Support Center', path: '/qna' },
  { name: 'Privacy Policy', path: '/privacy-policy' },
  { name: 'Cookie Notice', path: '/cookie-notice' },
];

const helpSupport = [
  // { name: 'How To Order', path: '/how-to-order' },
  { name: 'How To Track', path: '/how-to-track-order' },
  { name: 'Size Guide', path: '/size-guide' },
  { name: 'Refund Policy', path: '/refund-policy' },
  { name: 'Shipment Policy', path: '/shipping-return-cancellation-policy' },
];

const payWith = [
  { icon: <Image src={yasPay} alt="Airtel Money" w='50px' h='35px' /> },
  { icon: <Image src={mPesa} alt="mPesa" w='50px' h='35px' /> },
  { icon: <Image src={airtelMoney} alt="Airtel Money" w='60px' h='35px' /> },
  { icon: <Image src={eziPay} alt="mPesa" w='50px' h='35px' /> },
  { icon: <Image src={payPal} alt="mPesa" w='50px' h='35px' /> },
];

const stayConnected = [
  { icon: <FaFacebook size="30px" />, path: 'https://www.facebook.com/kwelibuy' },
  { icon: <FaTiktok size="30px" />, path: 'https://www.tiktok.com/@kwelibuy' },
  { icon: <FaInstagram size="30px" />, path: 'https://www.instagram.com/kweli_buy' },
  { icon: <FaXTwitter size="30px" />, path: 'https://x.com/kweliBuy' },
  { icon: <FaYoutube size="30px" />, path: 'https://www.youtube.com/@kwelibuy' },
];

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = localStorage.getItem('token') ? true : false;

  const handleLogout = () => {
    localStorage.clear();
    let guestId = uuidv4();
    localStorage.setItem('guestInfo', JSON.stringify({ id: guestId }));
    dispatch(setGuestInfo(guestId));
    window.location.href = '/';
  };

  return (
    <Flex direction="column" bg="#F0F0F0" height="100%">
      {/* Header */}
      <AccountHeader />

      {/* Customer Services Section */}
      <Box bg="#fff" p="20px" mt='8px'>
        <Text fontWeight={700} fontSize="16px" mb="12px" ml='10px'>Customer Services</Text>
        {customerServices.map((item, index) => (
          <React.Fragment key={index}>
            <Flex
              justify="space-between"
              align="center"
              cursor="pointer"
              onClick={() => navigate(item.path)}
              py="12px"
              ml='40px'
            >
              <Text fontSize="16px">{item.name}</Text>
            </Flex>
            {index < customerServices.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>

      {/* Help & Support Section */}
      <Box bg="#fff" mt="8px" p="20px">
        <Text fontWeight={700} fontSize="16px" mb="12px" ml='10px'>Help & Support</Text>
        {helpSupport.map((item, index) => (
          <React.Fragment key={index}>
            <Flex
              justify="space-between"
              align="center"
              cursor="pointer"
              onClick={() => navigate(item.path)}
              py="12px"
              ml='40px'
            >
              <Text fontSize="16px">{item.name}</Text>
            </Flex>
            {index < helpSupport.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>

      {/* pay with  */}
      <Box bg="#fff" mt="8px" p="20px">
        <Text fontWeight={700} fontSize="16px" mb="12px" ml="10px">Pay with</Text>
        <Flex ml="40px" gap="30px" align="center" wrap="wrap">
          {payWith.map((item, index) => (
            <Box key={index} 
            cursor="pointer" 
            width={{ base: 'calc(25% - 30px)', md: 'calc(25% - 30px)' }}
            onClick={() => item.path && navigate(item.path)}>
              {item.icon}
            </Box>
          ))}
        </Flex>
      </Box>

      {/* stay Connected */}
      <Box bg="#fff" mt="8px" p="20px" mb="58px">
        <Text fontWeight={700} fontSize="16px" mb="12px" ml="10px">Stay Connected</Text>
        <Flex ml="40px" gap="30px" align="center">
          {stayConnected.map((item, index) => (
            <Box key={index} cursor="pointer" onClick={() => window.open(item.path, '_blank', 'noopener,noreferrer')}>
              {item.icon}
            </Box>
          ))}
        </Flex>
      </Box>

      {/* Logout Button */}
      {/* {isLoggedIn && (
        <Flex
          padding={'20px 25px'}
          bg='#fff'
          align={'center'}
          cursor={'pointer'}
          onClick={handleLogout}
          mt='8px'
        >
          <Box
            onClick={() => {
              localStorage.clear();
              localStorage.setItem('isAuthenticated', 'true');
              let guestId = uuidv4();
              localStorage.setItem(
                'guestInfo',
                JSON.stringify({ id: guestId })
              );
              dispatch(setGuestInfo(guestId));

              window.location.href = '/';
            }}
          >
            <Flex gap={'16px'} align={'center'}>
              <LogoutIcon />
              <Text fontSize={'16px'} fontWeight={600} color="red">
                Logout
              </Text>
            </Flex>
          </Box>
        </Flex>
      )} */}
    </Flex>
  );
};

export default Settings;
