import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { getData } from '../../services/staticpage-service';

const OrderTrackingGuide = () => {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const getPageData = async (order_tracking_guide) => {
    setLoading(true);
    try {
      const res = await getData(order_tracking_guide);
      setData(res.data.data);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getPageData('order_tracking_guide');
  }, []);

  return (
    <Box
      padding='20px'
      borderBottomRightRadius='8px'
      borderBottomLeftRadius='8px'
      minH='50vh'
    >
      <Flex
        align='center'
        justify='center'
        width='100%'
        bg='white'
        flexDir='column'
        mx='auto'
        borderRadius='8px'
        mb='15px'
      >
        <Box
          width='100%'
          // bg="#f41106"
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='60px'
          color='black'
          // gap="20px"
          // padding="10px"
          borderTopRightRadius='8px'
          borderTopLeftRadius='8px'
        >
          <Text
            fontSize={{ base: '19px', md: '24px' }}
            fontWeight='600'
            textAlign='center'
          >
            How to Track Order
          </Text>
        </Box>

        {loading ? (
          'Loading...'
        ) : (
          <Text
            alignItes='center'
            padding={{ base: '10px 5px', md: '30px 50px' }}
            width='100%'
          >
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default OrderTrackingGuide;
